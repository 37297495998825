

.home-page {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden !important;
  .logo{
    span{
     font-size:9rem !important;
    }
   }
  
  .heroes{
   
    display: flex;
    justify-content: center; /* Horizontal centering */
    align-items: center; /* Vertical centering */
    width: 100%; /* Ensure the parent is full width */
    height: 100vh; /* Full viewport height */
    position: relative; /* Important for absolute positioning inside */
    .hero {
      position: absolute;

      // margin-top: 6.25rem;
     
  
      h1 {
        font-family: "sys";
        // color: var(--app-hero-h1-color);
        font-size: 6rem;
  
        .name {
          display: inline-block;
         margin:1rem;
          span {
            width: auto;
            margin-right: -0.625rem;
            margin-left: 0.5rem;
            margin-bottom: 1rem;
            opacity: 0;
            height: 4.4rem;
            animation: blink 3s linear infinite;
            // animation-delay: 5s;
          }
  
          @media only screen and (max-width: 520px) {
            span {
              font-size: 3.75rem;
              margin-right: -0.625rem;
              opacity: 0;
              height: auto;
              animation: blink 3s linear infinite;
            }
          }
        }
      }
  
      h2 {
        // color: var(--app-hero-h2-color);
        margin-top: 1.25rem;
        font-size: 1rem;
        font-family: "sys";
        letter-spacing: 0.20rem;
        animation: fadeIn 1s 1.8s backwards;
      }
  
      .flat-button {
        // color: var(--app-hero-button-color);
        font-size: 1rem;
        font-weight: 400;
        font-family: "sys";
        text-decoration: none;
        letter-spacing: 0.20rem;
        padding: 0.6rem 1.1rem;
        // border: 0.0625rem solid var(--app-hero-button-color);
        border: 0.0625rem solid;
        border-radius: 0.3rem;
        
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;
  
        &:hover {
          color: var(--app-hero-button-hover-color);
          background: var(--app-hero-button-color);
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .home-page {
    .logo{
      span{
       font-size:5rem !important;
      }
     }
    .heroes{
    .hero {
      margin-top: 5.125rem;

      h1 {
        font-size: 3.5rem;

        .name {
          img {
            width: 3.125rem;
            margin-top: 0.4rem;
            margin-right: -0.4rem;
            height: auto;
          }
        }
      }

      h2 {
        font-size: 0.8rem;
        letter-spacing: 0.125rem;
      }

      .flat-button {
        font-size: 0.8rem;
      }
    }
  }
  }
}


