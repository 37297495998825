.text-animate {
  display: inline-block;
  opacity: 0;
  animation: bounceIn 1s 1s;
  animation-fill-mode: forwards;
  min-width: 1rem;
  font-size: 5rem;
}

.text-animate-hover {
  display: inline-block;
  min-width: 1rem;
  font-size: 5rem;
  animation-fill-mode: both;

  &:hover {
    animation: flash 1s;
    animation-fill-mode: both;
    color: var(--app-hero-animate-color);
  }
}

@for $i from 1 through 38 {
  .text-animate._#{$i} {
    animation-delay: #{calc($i / 10)}s;
  }
}

@media only screen and (max-width: 768px) {
  .text-animate {
    min-width: 0.6rem;
    font-size: 3rem;
  }

  .text-animate-hover {
    min-width: 0.6rem;
    font-size: 3rem;
  }
}