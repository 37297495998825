* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --app-loader-color: #404040; /* Dark gray for loader and accents */
  --app-navbar-color: #202020; /* Very dark gray for a clear contrast */
  --app-link-color: #404040; /* Dark gray */
  --app-link-active-color: #404040; /* Dark gray, kept the same for consistency */
  --app-link-hover-color: #ffffff; /* White for a stark contrast and visibility when hovered */
  --app-link-small-color: #404040; /* Dark gray for smaller links */

  --app-background-color: black; /* Near black for backgrounds, enhances other elements */

  --app-hero-tag-color: #404040; /* Dark gray */
  --app-hero-h1-color: #ffffff; /* White for main titles to ensure readability */
  --app-hero-animate-color: white; /* Dark gray for animated elements */
  --app-hero-h2-color: #ffffff; /* White for secondary titles */
  --app-hero-button-color: #404040; /* Dark gray */
  --app-hero-button-hover-color: #ffffff; /* White for more noticeable button hover states */

  --app-tag-body-color: #404040; /* Dark gray */
  --app-tag-h1-color: #ffffff; /* White */
  --app-h1-animate-color: #404040; /* Dark gray */
  --app-h1-color: #ffffff; /* White */
  --app-p-color: #ffffff; /* White */

  --app-cube-color: rgba(64, 64, 64, 0.5); /* Transparent dark gray */
  --app-cube-border-color: #404040; /* Solid dark gray */
  --app-cube-glow-color: #404040; /* Dark gray */

  --app-contact-input-bg-color: rgba(16, 16, 16, 0.1); /* Slight transparency of very dark gray */
  --app-contact-input-text-color: #404040; /* Dark gray */
  --app-contact-placeholder-color: #ffffff; /* White for more contrast */
  --app-contact-textarea-bg-color: rgba(16, 16, 16, 0.1); /* Slight transparency of very dark gray */
  --app-contact-textarea-text-color: #404040; /* Dark gray */
  --app-contact-button-color: #404040; /* Dark gray */
  --app-contact-button-hover-color: #ffffff; /* White for more noticeable hover effect */

  --app-map-bg-color: #101010; /* Near black for map background */
  --app-map-text-color: #404040; /* Dark gray for map text */
  --app-map-email-color: #404040; /* Dark gray for email links */
  --app-map-animate-color: rgba(64, 64, 64, 0.1); /* Transparent dark gray */
  --app-contact-hero-animate-color: red;
}










html {
  font-size: 1em;
}

body {
  font: 300 0.9rem/1.4 "sys", "sans-serif";
  color: #444;
  color: #444;
  /* background: var(--app-background-color); */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
