.about-page {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text-zone {
  margin: -0.7rem 5rem;
  display: flex;
  flex-direction: column;
  max-width: 700px;
  position: relative;

}
.text-zone .headers {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  height: auto; /* Or adjust height as needed */
  margin: -1rem 0 4.4rem;
}

.text-zone .headers h1 {
  position: absolute; /* Only if needed */
 
  font-size: 3.3rem;
  font-family: "sys";
  font-weight: 400;
  text-align: center; /* Center text if needed */
  .text-animate-hover {
    
        
    &:hover {
 
      color: var(--app-contact-hero-animate-color);
    }
  }
}


.text-zone p {
  margin-bottom: 1rem;
  font-size: 1rem;
  color: var(--app-p-color);
  font-family: "sys";
  font-weight: 300;
  max-width: fit-content;
  animation: pulse 1s;
}

.text-zone p:nth-of-type(1) {
  animation-delay: 1.2s;
}

.text-zone p:nth-of-type(2) {
  animation-delay: 1.3s;
}

.text-zone p:nth-of-type(3) {
  animation-delay: 1.4s;
}

.cube-section {
  width: inherit;
  display: flex;
  justify-content: flex-end;
  max-width: 60rem;
}

.cube-container {
  padding: 6.25rem;
  width: 25rem;
  height: 25rem;
  overflow: hidden;
}

.cube-container .cubespinner {
  animation-name: spincube;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 12s;
  transform-style: preserve-3d;
  transform-origin: 6.25rem 6.25rem 0;
  margin-left: calc(50% - 6.25rem);
}

.cube-container .cubespinner div {
  position: absolute;
  width: 12.5rem;
  height: 12.5rem;
  border: 0.06rem solid var(--app-cube-border-color);
  background: var(--app-cube-color);
  text-align: center;
  font-size: 6.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 2.5rem 0 var(--app-cube-glow-color);
}

.cube-container .cubespinner .face1 {
  transform: translateZ(6.25rem);
  img{
    height: 10rem;
  }
}

.cube-container .cubespinner .face2 {
  transform: rotateY(90deg) translateZ(6.25rem);
  color: #f06529;
}

.cube-container .cubespinner .face3 {
  transform: rotateY(90deg) rotateX(90deg) translateZ(6.25rem);
  color: #28a4d9;
}

.cube-container .cubespinner .face4 {
  transform: rotateY(180deg) rotateZ(90deg) translateZ(6.25rem);
  color: #5ed4f4;
}

.cube-container .cubespinner .face5 {
  transform: rotateY(-90deg) rotateZ(90deg) translateZ(6.25rem);
  color: #efd81d;
}

.cube-container .cubespinner .face6 {
  transform: rotateX(-90deg) translateZ(6.25rem);
  color: #ec4d28;
}

@keyframes spincube {

  from,
  to {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }

  16% {
    transform: rotateY(-90deg) rotateZ(90deg);
  }

  33% {
    transform: rotateY(-90deg) rotateX(0deg);
  }

  50% {
    transform: rotateY(-180deg) rotateZ(90deg);
  }

  66% {
    transform: rotateY(-270deg) rotateX(90deg);
  }

  83% {
    transform: rotateX(90deg);
  }
}

@media only screen and (max-width: 768px) {
  .about-page .text-zone {
    margin: 0 1rem;
  }

  .about-page .text-zone h1 {
    font-size: 3rem;
  }

  .about-page .text-zone p {
    font-size: 0.7rem;
  }
}