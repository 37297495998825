.navbar {
  background-color: var(--app-navbar-color);
  position: sticky;
  top: 0;
  padding: 0.5rem 0.5rem;
  z-index: 3;
  flex-direction: column;

  a{
    font-family: "sys";
    font-size: 2rem;
  }

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
  
    &.links {
      a {
        padding: 0.3rem;

        svg {
          color: var(--app-link-small-color);
        }
      }

      a:hover {
        svg {
          color: var(--app-link-hover-color);
        }
      }
    }

    &.navs {
      a {
        position: relative;
        padding: 0.6rem;
        // font-size: 1.4rem;

        svg {
          color: var(--app-link-color);
        }

        i {
          transition: all 0.3s ease-out;
        }

        &:hover {
          color: var(--app-link-hover-color);

          svg {
            opacity: 0;
          }

          &:after {
            opacity: 1;
          }
        }

        &:after {
          content: "";
          font-size: 0.8rem;
          letter-spacing: 0.125rem;
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          top: 50%;
          left: 0;
          right: 0;
          transform: translateY(-50%);
          opacity: 0;
          -webkit-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out;
        }

        &:first-child {
          &::after {
            content: "HOME";
          }
        }
      }

      a.about-link {
        &:after {
          content: "ABOUT";
        }
      }

      a.contact-link {
        &:after {
          content: "CONTACT";
        }
      }

      a.active {
        svg {
          color: var(--app-link-active-color);
        }
      }
    }
  }

  
}
.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5rem;
  &:hover{
    span{
      color: white;
    }
    
  }
  span {
    text-align: center;
    font-size: 4rem;
    animation: spazz2 100s steps(1) infinite; // 15s instead of 7s
  }
}
@media only screen and (max-width: 768px) {
  .navbar {
    flex-direction: row;

    section {
      flex-direction: row;
    }

    .logo {
      margin-top: 0;

    }
  }
}