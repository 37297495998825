.contact-page {
  flex-direction: row;
  align-items: center;

  .form-section {
   display: flex;
 flex-direction: column;
padding: 0 5%;
    .headers{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      height: auto; /* Or adjust height as needed */
    
      h1 {
        position: relative;
        font-size: 3.3rem;
        font-family: "sys";
        font-weight: 400;
        color: var(--app-h1-color);
        .text-animate-hover {
          &:hover {
              color: var(--app-contact-hero-animate-color);
          }
        }
      }
    }

    p {
      margin-bottom: 1rem;
      font-size: 1rem;
      color: var(--app-p-color);
      font-family: "sys";
      font-weight: 300;
      max-width: fit-content;
      animation: pulse 1s;

      &:nth-of-type(1) {
        animation-delay: 1.2s;
      }
    }

    .contact-form {
      opacity: 0;
      animation: fadeInUp 2s 2s;
      animation-fill-mode: forwards;
      ul {
        padding: 0;
        margin: 0;
       
        li {
          padding: 0;
          margin: 0;
          list-style: none;
          margin-bottom: 0.625rem;
          overflow: hidden;
          display: block;
          position: relative;
        
          clear: both;
        }

        li.half {
          width: 49%;
          margin-left: 2%;
          float: left;
          clear: none;

          &:first-child {
            margin-left: 0;
          }
        }
      }

      input[type="text"],
      input[type="email"] {
        width: 100%;
        // border: 0;
        // background: var(--app-contact-input-bg-color);
        height: 3.125rem;
        font-size: 1rem;
        // color: var(--app-contact-input-text-color);
        padding: 0 1.25rem;
        box-sizing: border-box;
      }
      // input[type="submit"]{
      //   border: 0.0625rem solid var(--app-hero-button-color);
      // }

      // textarea::placeholder,
      // input[type="text"]::placeholder,
      // input[type="email"]::placeholder {
      //   color: var(--app-contact-placeholder-color);
      // }

      textarea {
        width: 100%;
        // border: 0;
        // background: var(--app-contact-textarea-bg-color);
        height: 3.125rem;
        font-size: 1rem;
        // color: var(--app-contact-textarea-text-color);
        padding: 20px;
        min-height: 150px;
        box-sizing: border-box;
      }

      // .flat-button {
      //   color: var(--app-contact-button-color);
      //   background: 0 0;
      //   font: 1rem;
      //   font-family: "sys";
      //   letter-spacing: 0.20rem;
      //   text-decoration: none;
      //   padding: 0.5rem 0.625rem;
      //   border: 0.0625rem solid var(--app-contact-button-color);
      //   float: right;
      //   border-radius: 0.25rem;

      //   &:hover {
      //     color: var(--app-contact-button-hover-color);
      //     background: var(--app-contact-button-color);
      //   }
      // }
    }

    .contact-links {
      display: flex;
      align-items: center;
      justify-content: center;
      animation: fadeInUp 2s 2s;
      animation-fill-mode: forwards;
      clear: both;
      opacity: 0;

      a {
        svg {
          color: var(--app-link-color);
          font-size: 2rem;
          padding: 1rem;
        }

        &:hover {
          svg {
            color: var(--app-link-hover-color);
          }
        }
      }
    }
  }

  .map-section {
    display: flex;
    
    width: 100%;
    height: 100%;
    min-height: 50%;

    .info-map {
      position: absolute;
      background-color: var(--app-map-bg-color);
      margin-top: 8rem;
      z-index: 999999;
      width: auto;
      height: fit-content;
      padding: 1.25rem;
      color: var(--app-map-text-color);
      font-family: "sys";
      font-size: 1rem;
      font-weight: 300;
      opacity: 0;
      animation: fadeIn 1s 1.5s;
      animation-fill-mode: forwards;

      span {
        font-size: 1rem;
        display: block;
        padding-top: 1.25rem;
        color: var(--app-map-email-color);
      }
    }

    .map-wrap {
      background: var(--app-map-animate-color);
      width: 100%;
      height: inherit;
    }

    .leaflet-container {
      width: 100%;
      height: inherit;
      opacity: 0;
      animation: backInRight 1s 1.2s;
      animation-fill-mode: forwards;
    }
  }
}

@media only screen and (max-width: 768px) {
  .contact-page {
    flex-direction: column;

    .form-section {
      padding-top: 10rem;
      p {
        font-size: 0.8rem;
      }
    }

    .map-section {
      padding: 5%;
      
      .info-map {
        
        padding: 1rem;
        font-size: 0.8rem;

        span {
          font-size: 0.8rem;
          padding-top: 1rem;
        }
      }
    }
  }
}

/* Classic Macintosh form styles */
.mac-form {
  background-color: var(--mac-window-color, #ffffff);
  border: 1px solid var(--mac-border-color, #000000);
  padding: 1rem;
}

.mac-form label {

  font-weight: bold;
  margin-bottom: 0.3rem;
  color: var(--mac-text-color, #000000);
}

.mac-form input,
.mac-form textarea {
  width: 100%;
  border: 1px solid var(--mac-border-color, #000000);
  background-color: var(--mac-input-bg-color, #ffffff);
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 0; /* Ensure no rounded corners */
  outline: none; /* Remove the default focus outline */
}

.mac-form input:focus,
.mac-form textarea:focus {
  outline: none; /* Remove the focus outline */
  border-color: var(--mac-border-color); /* Adjust border color if needed */
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2); /* Adjust color and radius for desired focus effect */
  border-radius: 0; /* Set border radius for focus outline */
}

.mac-form .half {
  width: 48%;

  margin-right: 4%;
}

.mac-form .half:last-child {
  margin-right: 0;
}

.mac-button {
  // width: 100%;
  border: 1px solid var(--mac-button-border-color, #000000);
  background-color: var(--mac-button-bg-color, #ffffff);
  padding: 0.5rem 1rem;
  cursor: pointer;
  display: block;
  text-align: center;
  color: var(--mac-text-color, #000000);
  border-radius: 0; /* Ensure no rounded corners */
  outline: none; /* Remove the default focus outline */
}

.mac-button:focus {
  outline: none; /* Remove the focus outline */
  border-color: var(--mac-button-border-color); /* Adjust border color if needed */
}

.mac-button:hover {
  background-color: var(--mac-bg-color, #c0c0c0);
}

iframe{
  width: 100%;
  height: 100%;
}