.layout {
  display: flex;
  flex-direction: row;
  background-color: var(--app-background-color);
  min-width: 15rem;
  max-height: 100vh;
  overflow: auto;
  z-index: 1;

  .page-container {
    will-change: contents;
    max-height: 100vh;
    position: absolute;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    // .video {
    //   z-index: 0;
    //   position: absolute;
    //   filter: brightness(50%);
    //   align-self: center;
    //   justify-content: center;
    //  width: 110vw;
    //   zoom: 20%;
    // }
    .bg-img-container {
      // filter: hue-rotate(45deg);
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100vw;
      height: 100vh;
      // animation: fadeInTopRight 2s forwards;
      // animation-delay: 2s;
      // opacity: 0;
      z-index: 2;

      img {
        height: 125rem;
        width: 125rem;
        animation: zoomInRight 7s forwards;
        animation-delay: 1s;
        opacity: 1;
      }
    }

    .page {
      position: relative;
      padding: 2.5rem 2.5rem 2.5rem 6.25rem;
      height: 100vh;
      width: 100vw;
      overflow: hidden;
      display: flex;
      overflow-x: hidden;
      transform-style: preserve-3d;
      animation: fadeIn 1s forwards;
      animation-delay: 1s;
      opacity: 0;
      z-index: 2;
    }
  }
}

@media only screen and (max-width: 768px) {
  .layout {
    display: flex;
    flex-direction: column;
  }

  .page {
    padding: 2.5rem 0.625rem 0.625rem 0.625rem !important;
    overflow-y: scroll !important;
  }
}

@media only screen and (max-height: 768px) {
  

  .page {
    
    overflow-y: scroll !important;
  }
}